<template>
    <div>
        <div class="entrepot-title-all">
            <div class="entrepot-title">
                <div @click="warehouseClick(1)" :class="[pageType == 1?'entrepot-color':'']">出货记录</div>
                <div @click="warehouseClick(2)" :class="[pageType == 2?'entrepot-color':'']">开单</div>
            </div>
            <div v-show="pageType == 2" class="entrepot-title-all">
				<div style="padding-right: 20px;" v-show="scanStatus">
					<div style="white-space: nowrap;">出库数量：{{totalNum}}</div>
					<div style="white-space: nowrap;">金额统计：{{totalMony}}</div>
				</div>
                <el-input ref="inputCode" v-show="scanStatus" v-model="form.code" class="code-class" :autofocus="true" @change="GteCode" @blur="blurInput"  placeholder="请扫码"></el-input>
                <el-input v-model="datafrom.goods_type"  @keyup.enter.native="getInfo()" placeholder="输入型号"></el-input>
                <el-button v-show="!scanStatus" class="addButton-class"  @click="SatraScanClick(1)" >启动扫码出货</el-button>
                <el-button v-show="scanStatus" class="addButton-class"  @click="SatraScanClick(2)" >关闭扫码出货</el-button>
            </div>
            
        </div>

        <div v-show="pageType == 1">
            <el-table
            v-loading="loading"
            element-loading-text="拼命加载中"
            element-loading-background="#fff"
            :data="tableData"
            border
            stripe
            style="width: 100%">
                <el-table-column
                    label="开单编号">
                    <template slot-scope="scope">
                        <!--展示二维码的容器-->
                        <div class="qrcode" :id="scope.row.no">{{scope.row.no}}</div>
                    </template>
                </el-table-column>

                <el-table-column
                    label="开单编号二维码">
                    <!-- <template slot-scope="scope"> -->
                        <template scope="scope">
                            <vue-qr v-if="scope.row.no" :margin="10"  :text="scope.row.no" :size="100"></vue-qr>
                            <div v-else>暂无数据</div>
                        </template>
                    <!-- </template> -->
                </el-table-column>

                <el-table-column
                    label="开单日期">
                    <template slot-scope="scope">
                        <span >{{scope.row.create_time}}</span>
                    </template>
                </el-table-column>
                <el-table-column
                    label="出库商场">
                    <template slot-scope="scope">
                        <span >{{scope.row.warehouse}}</span>
                    </template>
                </el-table-column>
                <el-table-column
                    prop="num"
                    width="80"
                    label="出库数量">
                </el-table-column>
                <el-table-column
                    prop="total"
                    label="出库总额">
                </el-table-column>
                <el-table-column
                    prop="suggest"
                    label="反馈信息">
                </el-table-column>
                <el-table-column
                    label="出货状态">
                    <template slot-scope="scope">
                        <el-tag type="success" v-if="scope.row.is_put_in">已入库</el-tag>
                        <el-tag type="danger" v-else >未入库</el-tag>
                    </template>
                </el-table-column>
                <el-table-column
                    label="备注信息">
                    <template slot-scope="scope">
                        <!--展示二维码的容器-->
                        <span  class="beizhu">{{scope.row.remark}}</span>
                    </template>
                </el-table-column>
                <el-table-column
                    label="详细">
                    <template slot-scope="scope">
                        <span @click="seeAllClick(scope.row)" class="seeLock">查看</span>
                    </template>
                </el-table-column>
                
                

                <el-table-column
                    label="操作">
                    <template slot-scope="scope">
                        <el-button @click="delClick(scope)" type="text" size="small">删除</el-button>
                    </template>
                </el-table-column>
            </el-table>

            <el-pagination
            class="table-limit"
            @size-change="handleSizeChange"
            @current-change="handleCurrentChange"
            :current-page="currentPage"
            :page-sizes="pageArray"
            :page-size= this.limit.page_size
            layout="total, sizes, prev, pager, next, jumper"
            :total="total">
            </el-pagination>
        </div>

        <div v-show="pageType == 2">
            <el-table
            v-loading="loading"
            element-loading-text="拼命加载中"
            element-loading-background="#fff"
            :data="tableDatas"
            border
            stripe
            style="width: 100%">
                <el-table-column
                    label="条形码">
                    <template slot-scope="scope">
                        <span >{{scope.row.bar_code}}</span>
                    </template>
                </el-table-column>
                <el-table-column
                    label="二维码">
                    <template scope="scope">
                        <vue-qr v-if="scope.row.qr_code"  :margin="10" :text="scope.row.qr_code" :size="100"></vue-qr>
                        <div v-else>暂无数据</div>
                    </template>
                </el-table-column>
                <el-table-column
                    label="商品图片">
                    <template slot-scope="scope">
                        <el-image
                        style="width: 100px; height: 100px"
                        :src="scope.row.goods_url"
                        ></el-image>
                    </template>
                </el-table-column>
                <el-table-column
                    label="型号">
                    <template slot-scope="scope">
                        {{scope.row.goods_type}}
                    </template>
                </el-table-column>
                <el-table-column
                    label="品牌">
                    <template slot-scope="scope">
                        {{scope.row.goods_brand}}
                    </template>
                </el-table-column>
                <el-table-column
                    label="商品规格">
                    <template slot-scope="scope">
                        {{scope.row.goods_norms}}
                    </template>
                </el-table-column>
                <el-table-column
                    label="类别">
                    <template slot-scope="scope">
                        {{scope.row.goods_class}}
                    </template>
                </el-table-column>
                
                <el-table-column
                    prop="price"
                    label="单价">
                    <template slot-scope="scope">
                        {{scope.row.price}}
                    </template>
                </el-table-column>
                <el-table-column
                    prop="zk"
                    label="折扣">
                </el-table-column>
                <el-table-column
                    prop="total"
                    label="折扣金额">
                </el-table-column>
                <el-table-column
                    prop="num"
                    label="数量">
                </el-table-column>
                <el-table-column
                    prop="reward"
                    label="奖励">
                </el-table-column>


                <el-table-column
                    label="操作">
                    <template slot-scope="scope">
                        <el-button @click="delClcik(scope)" type="text" class="seeLock">删除</el-button>
                    </template>
                </el-table-column>
            </el-table>


            <el-row class="bottom-all-all">
                <el-col :span="8">
                    <el-form :inline="true" :model="formInline" >
                         <el-form-item label="仓库选择">
                            <el-select v-model="form.warehouse_id" filterable @change="selectClick" placeholder="查询选项">
                                <el-option  v-for="(item,key) in sales_mao_list" :key="key"  :label="item.name" :value="item.id"></el-option>
                            </el-select>
                        </el-form-item>
                    </el-form>
                </el-col>
                <el-col :span="8">
                    <el-form :inline="true" :model="formInline" >
                         <el-form-item label="仓库备注">
                            <el-input v-model="remark"  placeholder="请输入备注"></el-input>
                        </el-form-item>
                    </el-form>
                </el-col>
                <el-col :span="8" style="text-align: center;">
                    <div>出账日期：{{NowTime}}</div>
                </el-col>
                <el-col :span="8" style="text-align: right;">
                    <el-button @click="affirmButtonClick" class="affirmCLick">确认出货</el-button>
                </el-col>
            </el-row>
        </div>

    </div>
</template>

<script>
import { all_warehouse,ship_list,scan_goods_info,ship_affirm,write_goods_info,ship_del} from '@/api/shipment.js'
import { sales_list } from '@/api/sales.js'
export default {
    name:'Entrepot',
    data(){
        return{
            limit:{
                page:1,
                page_size:10, 
            },
            pageType:1,
            loading:true,
            tableData:[],
            total:0,
            currentPage:1,
            dialogFormVisible:false,
            scanStatus:false,
            form:{
                code:'',
                warehouse_id:'',
            },
            formInline: {
                user: '',
                region: ''
            },
            datafrom:{
                goods_type:'',
            },
            remark:'',
            NowTime:'',
            sales_mao_list:[],
            tableDatas:[],
            pageArray:this.$store.state.pageArray,
        }
    },
    created(){
        // 获取首页数据
        this.getlist()
        // 获取仓库列表
        this.get_changkuList()
    },
	computed:{
		totalNum(){
			var number=0
			for(var i=0;i<this.tableDatas.length;i++){
				var num=this.tableDatas[i].num
				number+=parseFloat(num)
			}
			return number
		},
		totalMony(){
			// total
			var number=0
			for(var i=0;i<this.tableDatas.length;i++){
				var num=(this.tableDatas[i].price)*(this.tableDatas[i].num)
				number+=parseFloat(num)
			}
			return number
		}
	},
    methods:{
        // 仓库管理列表
        getlist(){
            ship_list(this.limit).then(res=>{
                let data = res.data.data
                // 双击编辑时候打开
                // data.forEach(element => {
                //     element.editStatus = false
                //     element.editButton = false
                // });
                this.tableData = data
                this.total = res.data.total
                this.loading = false
            })
			
        },
        //查看详情
        seeAllClick(row){
            let data = {
                create_time:row.create_time,
                warehouse:row.warehouse,
                id:row.id,
                qr:row.no
            }
            this.$router.push('/record?key=' +  JSON.stringify(data))
        },
		// 删除出货记录元素
			delClick(scope){
				if(!scope.row.is_put_in){
					let data = {
					    id:scope.row.id,
					}
					ship_del(data).then(res=>{
						this.tableData.splice(scope.$index,1)
					    this.$message({
					        type: 'success',
					        message: '删除成功'
					    })
					})
				}
			},
        // 删除开单元素
        delClcik(scope){
            this.tableDatas.splice(scope.$index,1)
            this.$message({
                type: 'success',
                message: '删除成功'
            })
        },

        // 确定出货
        affirmButtonClick(){
            let arr = []
            this.tableDatas.forEach(element => {
                arr.push(element.id)
            });
            let data = {
                warehouse_id:this.form.warehouse_id,
                goods_ids:arr,
                remark:this.remark,
            }
            ship_affirm(data).then(res=>{
                if(res.code == 200){
                    this.tableDatas = []
                    this.$message({
                        type: 'success',
                        message: res.msg
                    })
                }
            })
        },

        // 获取仓库列表
        get_changkuList(){
            sales_list({all:true}).then(res=>{
                this.sales_mao_list = res.data
            })
        },

        //获取input
        GteCode(){
            let arr = []
            this.tableDatas.forEach(element=>{
                arr.push(element.id)
            })
            this.form.goods_ids = arr
            scan_goods_info(this.form).then(res=>{
                this.form.code = ''
                this.form.goods_ids = []
                if(res.code == 200){
                    this.tableDatas.unshift(res.data)
                }
            })

        },
        //仓库  库存切换
        warehouseClick(key){
            if(this.pageType == key ){
                return
            }
            this.getlist()
            this.pageType = key
            this.getNowTime()
        },
        // 输入框失焦
        blurInput(){
            this.SatraScanClick(2)
        },
        //启动/关闭扫码
        SatraScanClick(key){
			console.log(this.tableDatas)
            if(key == 1){
                if(!this.form.warehouse_id){
                    this.$message({
                        type: 'warning',
                        message: '请选择出货仓库'
                    })
                    return
                }
                this.scanStatus = true
                this.$nextTick(function () {
                    this.$refs.inputCode.focus()
                });
                this.$message({
                    type: 'success',
                    message: '启动成功，请不要点击鼠标,开始扫码'
                })
            }else{
                this.scanStatus = false
                this.$message({
                    type: 'success',
                    message: '关闭成功'
                })
                this.form.code=''
                this.scanStatus = false
            }
            
        },
        getInfo(){
            if(!this.form.warehouse_id){
                this.$message({
                    type: 'warning',
                    message: '请选择出货仓库'
                })
                return
            }
            let arr = []
            this.tableDatas.forEach(element=>{
                arr.push(element.id)
            })
            let data = {
                warehouse_id:this.form.warehouse_id,
                goods_type:this.datafrom.goods_type,
                goods_ids:arr
            }
            write_goods_info(data).then(res=>{
                if(res.code == 200){
                    this.datafrom.goods_type = ''
                    this.tableDatas.unshift(res.data)
                }
            })
        },

        // 下拉选择
        selectClick(e){
            this.form.warehouse_id = e
        },

        /**
         * 获取今天时间
         */
        getNowTime(){
            var myDate = new Date();
            let Y = myDate.getFullYear();  
            let M = myDate.getMonth() + 1;   
            let D = myDate.getDate();
            this.NowTime = Y + '.'+ M + '.' + D
            console.log(this.NowTime)
        },
        /**
         * 点击页码触发
         */
        handleSizeChange(val) {
            this.limit.page_size = val
            this.loading = true
            this.getlist()
        },
        handleCurrentChange(val) {
            this.limit.page = val
            this.loading = true
            this.getlist()
        }

    }
}
</script>

<style scoped>
.beizhu{
    color: #FFAF24;

}
.entrepot-title-all{
    display: flex;
    align-items: center;
    justify-content: space-between;
}
.entrepot-title{
    width: 220px;
    display: flex;
    align-items: center;
    justify-content: space-between;
    font-weight: bold;
    line-height: 90px;

}
.entrepot-color{
    color: #428CFF;
}
.addButton-class{
    background: linear-gradient(90deg, #FF861B, #FFB527);
    box-shadow: 0px 5px 30px 0px rgba(255, 142, 66, 0.4);
    border-radius: 6px;
    border: none;
    color: #fff;
    margin-left: 40px;

}
.seeLock{
    color: #FF5C41;
}
.code-class{
    width: 800px;
}
.affirmCLick{
    background: linear-gradient(90deg, #FF774E, #FF4C16);
    box-shadow: 0px 5px 30px 0px rgba(255, 77, 23, 0.4);
    border-radius: 6px;
    border: none;
    color: #fff;
}
.bottom-all-all{
    margin-top: 40px;
}
</style>