import service from '@/utils/request.js'

// 导入签名计算
import { encrypt } from '@/utils/encrypt'


/**************************************
 * 退货管理
 **************************************/
/**
 * 获取仓库列表
 */
export function sales_list(data={}) {
    data.sign = encrypt(data);
    return service.request({
        url: '/admin/warehouse/list',
        method: 'get',
        params:data
    })
}

/**
 * 
 * @param {分仓退货查询} data 
 */
export function fenbackgoodsinfo(data={}) {
    data.sign = encrypt(data);
    return service.request({
        url: '/admin/backgoods/fenbackgoodsinfo',
        method: 'post',
        data
    })
}


/**
 * 
 * @param {总仓库查询} data 
 */
export function backgoodsinfo(data={}) {
    data.sign = encrypt(data);
    return service.request({
        url: '/admin/backgoods/backgoodsinfo',
        method: 'post',
        data
    })
}


/**
 * 
 * @param {总仓退货提交} data 
 */
export function backgoods(data={}) {
    data.sign = encrypt(data);
    return service.request({
        url: '/admin/backgoods/backgoods',
        method: 'post',
        data
    })
}


/**
 * 
 * @param {分仓退货提交} data 
 */
export function fenbackgoods(data={}) {
    data.sign = encrypt(data);
    return service.request({
        url: '/admin/backgoods/fenbackgoods',
        method: 'post',
        data
    })
}


/**
 * 
 * @param {测试接口} data 
 */
export function scancode(data={}) {
    data.sign = encrypt(data);
    return service.request({
        url: '/admin/purchase/scancode',
        method: 'post',
        data
    })
}


/**
 * 
 * @param {总仓库退货查询} data 
 */
export function showbackgoods(data={}) {
    data.sign = encrypt(data);
    return service.request({
        url: '/admin/backgoods/showbackgoods',
        method: 'post',
        data
    })
}



/**
 * 
 * @param {总仓库退货查询} data 
 */
export function fenbackgoodsshow(data={}) {
    data.sign = encrypt(data);
    return service.request({
        url: '/admin/backgoods/fenbackgoodsshow',
        method: 'post',
        data
    })
}

